<template>
    <section class="view locations">
        <view-title v-bind:title="$t('message.locations.title')"></view-title>
        <map-wrapper v-bind:locations="branches"></map-wrapper>
    </section>
</template>

<script>
    import MapWrapper from "../components/Locations/MapWrapper";
    import ViewTitle from "../components/ViewTitle";

    export default {
        name: "Locations",
        components: {
            ViewTitle,
            MapWrapper
        },
        metaInfo: {
            title: "分店位置",
        },
        data () {
            return {

            }
        },
        computed: {
            branches () {
                return this.$store.state.mall.shop.branches;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .locations {

    }
</style>
