<template>
    <div class="map-wrapper row no-gutters">
        <div class="locations col-md-5 order-last order-md-0 no-gutters d-flex justify-content-center">
            <ul class="p-0 w-100 m-auto">
                <li class="location px-3 px-md-5"
                    v-for="location in locations"
                    v-bind:key="location._id"
                    v-bind:class="{ 'active': location.selected }"
                    v-on:click="selectLocation(location)">

                    <h5 v-if="location.name">
                        {{ location.name[$i18n.locale] }}
                    </h5>
                    <h5 v-else>
                        {{ locationTypeMap[location.type][$i18n.locale] }}
                    </h5>

                    <div class="row no-gutters">
                        <div class="col-1">
                            <font-awesome-icon icon="map-marker-alt"></font-awesome-icon>
                        </div>
                        <div class="col-11">
                            {{ location.address[$i18n.locale] }}
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-1">
                            <font-awesome-icon v-bind:icon="['fal', 'clock']"></font-awesome-icon>
                        </div>
                        <div class="col-11">
                            {{ location.openHours.from | dateFnsFormatter("HH:mm") }} - {{ location.openHours.to | dateFnsFormatter("HH:mm") }}
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-1">
                            <font-awesome-icon icon="phone"></font-awesome-icon>
                        </div>
                        <div class="col-11">
                            {{ location.phone }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="map col-md-7 order-first order-md-1 no-gutters">
            <GmapMap style="width: 100%; height: 100%;"
                     v-bind:center="mapCenter"
                     v-bind:zoom="mapZoom"
                     v-bind:options="{ styles: mapStyle }">
                <gmap-info-window v-bind:options="infoWindowOptions"
                                  v-bind:position="infoWindowPosition"
                                  v-bind:opened="infoWindowOpen"
                                  v-on:closeclick="unselectLocation(location)">
                    {{ infoContent }}
                </gmap-info-window>
                <GmapMarker v-for="location in locations"
                            v-bind:key="location._id"
                            v-bind:position="location.coordinate"
                            v-bind:clickable="true"
                            v-bind:draggable="false"
                            v-on:click="selectLocation(location)">
                </GmapMarker>
            </GmapMap>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MapWrapper",
        props: {
            locations: {
                type: Array,
                required: true
            }
        },
        data () {
            return {
                defaultMapCenter: {
                    lat: 22.384149,
                    lng: 114.1313199,
                },
                defaultMapZoom: 11,

                mapCenter: {
                    lat: 22.384149,
                    lng: 114.1313199,
                },
                mapZoom: 11,
                mapStyle: [
                    {
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#f5f5f5"
                        }]
                    }, {
                        "elementType": "labels.icon",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }, {
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#616161"
                        }]
                    }, {
                        "elementType": "labels.text.stroke",
                        "stylers": [{
                            "color": "#f5f5f5"
                        }]
                    }, {
                        "featureType": "administrative.land_parcel",
                        "elementType": "labels",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }, {
                        "featureType": "administrative.land_parcel",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#bdbdbd"
                        }]
                    }, {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#eeeeee"
                        }]
                    }, {
                        "featureType": "poi",
                        "elementType": "labels.text",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }, {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#757575"
                        }]
                    }, {
                        "featureType": "poi.business",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }, {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#e5e5e5"
                        }]
                    }, {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#9e9e9e"
                        }]
                    }, {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#ffffff"
                        }]
                    }, {
                        "featureType": "road",
                        "elementType": "labels.icon",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }, {
                        "featureType": "road.arterial",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#757575"
                        }]
                    }, {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#dadada"
                        }]
                    }, {
                        "featureType": "road.highway",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#616161"
                        }]
                    }, {
                        "featureType": "road.local",
                        "elementType": "labels",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }, {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#9e9e9e"
                        }]
                    }, {
                        "featureType": "transit",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }, {
                        "featureType": "transit.line",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#e5e5e5"
                        }]
                    }, {
                        "featureType": "transit.station",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#eeeeee"
                        }]
                    }, {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#c9c9c9"
                        }]
                    }, {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#9e9e9e"
                        }]
                    }],

                infoWindowOpen: false,
                infoWindowPosition: {
                    lat: 22.384149,
                    lng: 114.1313199,
                },
                infoWindowOptions: {
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                },
                infoContent: {},

                locationTypeMap: {
                    "salon": {
                        "zh-hk": "marier美容中心",
                        "zh-cn": "marier美容中心",
                        "en": "marier Salon"
                    },
                    "counter": {
                        "zh-hk": "marier專門店/專櫃",
                        "zh-cn": "marier专门店/专柜",
                        "en": "Marier Counter"
                    }
                },
            }
        },
        computed: {

        },
        methods: {
            selectLocation (location) {
                this.locations
                    .forEach(
                        location => location.selected = false
                    );
                location.selected = true;
                this.infoWindowPosition = location.coordinate;
                this.infoWindowOpen = true;
                this.infoContent = location.address["zh-hk"];
                this.mapCenter = location.coordinate;
                this.mapZoom = 14;
            },

            unselectLocation () {
                this.locations
                    .forEach(
                        location => location.selected = false
                    );
                this.infoWindowOpen = false;
                this.mapCenter = this.defaultMapCenter;
                this.mapZoom = this.defaultMapZoom;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .map-wrapper {
        & > .locations {
            min-height: 500px;
            height: 500px;
            background-color: rgba(113, 113, 113, 1);
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;

            ul {
                list-style: none;
                padding: 0 15px;
                margin: 0;

                & > li.location {
                    color: rgba(255, 255, 255, 1);
                    font-size: 16px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    cursor: pointer;

                    &.active {
                        background-color: rgba(255, 255, 255, 1);
                        color: rgba(113, 113, 113, 1);
                    }

                    & > h5 {
                        font-weight: 500;
                    }
                }
            }
        }

        & > .map {
            height: 500px;
        }
    }

    @media screen and (max-width: 768px) {
        .map-wrapper {
            & > .locations {
                height: auto;
            }
        }
    }
</style>
